@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sum-it-up-gradient {
  background: #CF0448;
  background: -webkit-linear-gradient(to right, #CF0448 0%, #478BFF 100%);
  background: -moz-linear-gradient(to right, #CF0448 0%, #478BFF 100%);
  background: linear-gradient(to right, #CF0448 0%, #478BFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark .sum-it-up-gradient {
  background: #FF0559;
  background: -webkit-linear-gradient(to right, #FF0559 0%, #5EB4FF 100%);
  background: -moz-linear-gradient(to right, #FF0559 0%, #5EB4FF 100%);
  background: linear-gradient(to right, #FF0559 0%, #5EB4FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}