.language-select * {
    z-index: 500 !important;
}

.language-select > div {
    @apply dark:bg-gray-800;
}

/*.language-select > div > div div {*/
.language-select > div > div div:not(:last-child) {
    @apply dark:bg-gray-900 dark:text-gray-100;
}

.language-select div {
    @apply dark:text-gray-100;
}

#react-select-2-input {
    @apply dark:bg-gray-800;
}

#react-select-2-listbox * {
    @apply dark:bg-gray-800;
}

